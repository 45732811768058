.dialog-overlay {
    position: fixed; /* Fullscreen overlay */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .dialog {
    background-color: white; /* Background color for the dialog */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    padding: 20px; /* Padding inside the dialog */
    width: 80%; /* Width of the dialog */
    max-width: 600px; /* Maximum width */
  }
  
  .dialog h4 {
    margin: 0 0 10px; /* Margin for the title */
  }
  
  .dialog button {
    margin-top: 10px; /* Space above the close button */
    padding: 10px 15px; /* Button padding */
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded button */
    cursor: pointer; /* Pointer on hover */
  }
  
  .dialog button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  