.timesheets-container {
  margin: 20px;
}

#month-year {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
}

.log-events-container {
  display: flex; /* Use flexbox for consistent behavior */
  width: 100%; /* Full width */
  flex-wrap: wrap; /* Allow wrapping of columns */
}

.log-event-column {
  width: 6cm; /* Fixed width of approximately 3 centimeters */
  min-width: 6cm; /* Ensure the width does not shrink below 3 cm */
  padding: 10px;
  text-align: left; /* Align contents to the left */
  box-sizing: border-box; /* Include padding and borders in width calculation */
  margin: 10px; /* Space between columns */
  max-height: 6500px; /* Set a maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #000; /* Add a border */
}

.month-summary-column {
  width: 20cm; /* Fixed width of approximately 15 centimeters */
  min-width: 20cm; /* Ensure the width does not shrink below 15 cm */
  padding: 10px;
  text-align: left; /* Align contents to the left */
  box-sizing: border-box; /* Include padding and borders in width calculation */
  margin: 10px; /* Space between columns */
  max-height: 650px; /* Set a maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #000; /* Add a border */
}

.month-details-column {
  width: 12cm; /* Fixed width of approximately 15 centimeters */
  min-width: 12cm; /* Ensure the width does not shrink below 15 cm */
  padding: 10px;
  text-align: left; /* Align contents to the left */
  box-sizing: border-box; /* Include padding and borders in width calculation */
  margin: 10px; /* Space between columns */
  max-height: 650px; /* Set a maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #000; /* Add a border */
}

.month-details-column div { /* Targeting each item in the month-details-column */
  margin: 0; /* Remove margins between items */
  padding: 0px; /* Padding for each section */
  font-size: 12px; /* Set font size to 10px */
  background-color: transparent; /* Optional: Set background color to transparent */
}

.horizontal-line {
  width: 100%; /* Full width of the column */
  height: 2px; /* Thickness of the line */
  background-color: black; /* Color of the line */
  margin-bottom: 10px; /* Space below the line */
  align-self: flex-start; /* Align at the start of the column */
}

/* Optional: Style the select box and labels */
label {
  font-weight: bold;
  margin-right: 10px;
}

p {
  font-size: 16px;
  color: #555;
}

.item-section {
  flex: 1; /* Default flex value for the last section */
  padding: 5px; /* Padding for each section */
  box-sizing: border-box; /* Include padding in width calculation */
  text-align: left; /* Left-align the text within each section */
}

.item-section.day {
  flex: 0 0 2cm; /* Fixed width of 3cm for Day/Number */
}

.item-section.type {
  flex: 0 0 3cm; /* Fixed width of 3cm for Type */
}

.staff-summary {
  display: flex; /* Enable flexbox */
  align-items: center; /* Align items vertically centered */
  gap: 10px; /* Adjust spacing between items */
}

.staff-name {
  font-weight: bold;
  font-size: 14px;
  width: 3cm; /* Fixed width */
}

.staff-role {
  font-style: italic;
  font-size: 12px;
  width: 2cm; /* Fixed width */
}

.summary-std-hours {
  color: blue; /* Example color */
  width: 6cm; /* Fixed width */
}

.summary-overtime {
  color: green; /* Example color */
  width: 3cm; /* Fixed width */
}

.summary-sick-holiday {
  
  width: 3cm; /* Fixed width */
}
