/* src/Login.css */
.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  font-size: 1.8em; /* Increased font size */
}

.form-group {
  margin-bottom: 15px; /* Spacing between form groups */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1em; /* Increased font size */
}

.form-group input {
  width: 100%;
  padding: 10px; /* Increased padding */
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1em; /* Increased font size */
}

.error-message {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* Spacing above button */
  font-size: 1em; /* Increased font size */
}

button:hover {
  background-color: #0056b3;
}
