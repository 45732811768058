/* Overlay background to cover the page when popup is open */
.sop-versions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent dark background */
  z-index: 999;  /* Ensure overlay is under the modal */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal */
}

/* Style for the actual popup */
.sop-versions-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 90%;  /* Prevent the popup from becoming too large */
  overflow-y: auto; /* Allow scrolling if content overflows */
  z-index: 1000; /* Ensure it's on top of the overlay */
  position: relative; /* To allow absolute positioning of the close button */
}

/* Close button at the top right corner of the popup */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 14px;  /* Adjust the font size of the button */
  padding: 5px 10px; /* Adjust padding to make button size appropriate */
  cursor: pointer;
  color: #333;  /* Ensure the button is visible */
}

/* Button style for uploading */
.upload-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.upload-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* List of SOP versions */
.versions-list {
  margin-top: 20px;
  max-height: 300px; /* Limit height for scrolling */
  overflow-y: auto;
}

/* Each version item */
.version-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.version-item:hover {
  background-color: #f1f1f1; /* Highlight version on hover */
}

/* Highlight for the latest version */
.version-item.latest-version {
  background-color: green;
  color: white;
}

/* Styles for the upload dialog section */
.upload-dialog {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

/* Buttons at the bottom of the dialog */
.dialog-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
 
.file-content-box {
  max-height: 400px;
  max-width: 600px;
  overflow: hidden;
}

 

.version-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.file-content-dialog {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 70%;
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 10;
}

.file-content-scroll {
  max-height: 300px;
  overflow-y: auto;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
}
