 /* Overlay to grey out the background when popup is active */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Below the popup but above other content */
}

/* Base font size adjustment */
body {
  font-size: 12px; /* Adjust this value as needed to reduce by 2px from the original */
}

/* Planning dialog styles */
.planning-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Adjusted width to be responsive */
  max-width: 1400px; /* Set a maximum width */
  height: 80%; /* Adjusted height to be responsive */
  max-height: 600px; /* Set a maximum height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Above the overlay */
  display: flex;
  flex-direction: column;
}

/* Container for left and right panels */
.planning-container {
  display: flex;
  gap: 20px;
  flex: 1; /* Make the container fill the available height */
  overflow: hidden; /* Prevent overflow on the container */
}

.left-panel, .right-panel {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling for each panel */
}

.left-panel {
  background-color: #f9f9f9;
}

.right-panel {
  background-color: #e9f7f9;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Scrollable sections inside the right panel */
.right-panel-section {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #ffffff;
}

.scrollable-box {
  max-height: 150px; /* Set a limit to the height for the scrollable sections */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.right-panel-section h3 {
  margin-top: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

/* Ensure that input fields fit the time format */
.time-input {
  width: 60px; /* Adjust the width according to the time format */
}

/* Additional styling for dialog body inputs */
.dialog-body input {
  display: inline-block;
  width: auto; /* Allow inputs to adjust width automatically */
  padding: 4px; /* Add padding for better usability */
  border: 1px solid #ccc; /* Add a border to input fields */
  border-radius: 4px; /* Slightly round the corners */
}

/* Optional: Add responsive adjustments */
@media (max-width: 600px) {
  .planning-dialog {
    width: 90%; /* Full width on small screens */
    height: auto; /* Allow height to adjust */
    max-height: none; /* Remove max height on small screens */
  }

  .planning-container {
    flex-direction: column; /* Stack panels vertically on small screens */
  }

  .left-panel, .right-panel {
    height: auto; /* Allow panels to adjust height */
  }
}
