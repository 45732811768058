.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-container {
    background: #fff;
    width: 60%; /* Reduced width for the entire popup */
    display: flex;
    padding: 20px;
    border-radius: 8px;
}

.popup-left, .popup-right {
    flex-grow: 1; /* Expand both sides equally */
    width: 50%; /* Ensure both sides take up half the space */
    padding: 20px;
}

.popup-right {
    /* Font size adjustment */
    font-size: 10px; /* Make the font size smaller */
    
    /* Set a max height for the scrollable area */
    max-height: 400px; /* Adjust this value as needed */
    
    /* Enable vertical scrolling when content exceeds max height */
    overflow-y: auto;
    
    /* Optional: add padding for better spacing */
    padding: 10px;
    
    /* Optional: add a border for visual separation */
    border-left: 1px solid #ccc;
}

.popup-left h3, .popup-right h3 {
    margin-bottom: 10px;
}

.popup-buttons {
    margin-top: 20px;
}

.popup-buttons button {
    margin-right: 10px;
}

.day-block {
    margin-bottom: 15px;
}

.day-date {
    font-weight: bold;
}

.entry {
    padding-left: 10px;
}

.entry.highlight {
    background-color: cyan;
}

.entry.highlight-minimum {
    background-color: lightgreen;
}

.entry.highlight-below-minimum {
    background-color: lightcoral;
}
