 /* Default style for location items */
.location-item {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

/* Hover state for the location items */
.location-item:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Highlight the selected location item */
.location-item.selected {
  background-color: lightblue; /* Highlight color for selected location */
  font-weight: bold; /* Optional: make the selected item bold */
}
/* Zebra striping */
.zebra-odd {
  background-color: #f9f9f9;
}
.zebra-even {
  background-color: #e0e0e0;
}

/* Delete icon style */
.delete-icon {
  margin-left: 10px;
  color: #c00;
  cursor: pointer;
}

/* Dialog overlay */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Dialog box */
.dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.location-item {
  display: flex;
  justify-content: space-between; /* Ensures space is between items */
  align-items: center; /* Centers items vertically */
}

.locations-grid {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional */
  padding: 10px; /* Optional */
}
