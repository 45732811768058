 /* Staff.css */

.container {
  display: flex;
  height: 100vh;
  width: 100vw; /* Ensure the container spans the full viewport width */
}

/* Left Panel: Fixed width with top border */
.left-section {
  width: 460px; /* Fixed width for left panel (adjust as needed) */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
  border-right: 1px solid #ccc;
  box-sizing: border-box; /* Ensures padding is included in the element's total width/height */
  position: relative; /* For absolute positioning of the border */
}

/* Apply a full-width top border */
.left-section::before, .middle-section::before, .right-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Thickness of the border */
  background-color: #007bff; /* Border color */
}

/* Middle and Right Panels */
.middle-section, .right-section {
  flex-grow: 1; /* Allows the panels to grow to fill available space */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
  box-sizing: border-box;
  padding: 10px;
  position: relative; /* For absolute positioning of the border */
}

/* Set the middle and right section widths to fill remaining space */
.middle-section {
  width: calc(50% - 230px); /* Takes half of the remaining width, minus space for left panel */
  border-right: 1px solid #ccc;
}

.right-section {
  width: calc(50% - 230px); /* Takes half of the remaining width, minus space for left panel */
}

/* Form and Select Styles */
.location-select, .form-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

/* Staff List */
.staff-list {
  margin-top: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.staff-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
}

.staff-item.even {
  background-color: #f0f0f0;
}

.staff-item.odd {
  background-color: transparent;
}

/* Tabs */
.tabs {
  display: flex;
  margin-bottom: 10px;
}

.tab-button {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  color: #000;
  cursor: pointer;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
}

.tab-content {
  margin-top: 10px;
}

/* Entry Fields */
.entry-fields {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.day-label {
  margin-right: 15px;
  font-weight: bold;
  min-width: 50px; /* Adjust this width based on the space you need for the day of the week */
  text-align: left;
}

.entry-field {
  flex: 1;
  margin-right: 10px;
}

.entry-input {
  width: 100%; /* Ensure inputs take the full width available */
}

/* Labels */
.entry-fields-labels, .entry-field-labels {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
}

.daily-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Buttons */
.save-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.loading, .error {
  text-align: center;
  padding: 20px;
}

/* Popup */
.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background: white;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup button {
  margin-top: 10px;
}

/* Chart Container */
.chart-container {
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  overflow-x: auto;
  margin-bottom: 20px; /* Space between chart and boxes */
}

/* Scrollbar styling */
.chart-container::-webkit-scrollbar {
  height: 10px;
}

.chart-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chart-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Boxes Container */
.boxes-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Individual Boxes */
.box {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 170px;
  overflow-y: auto;

}

.box h4 {
  margin-top: 0;
}

/* List inside Box */
.box ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.box li {
  margin-bottom: 10px;
}

.delete-icon {
  cursor: pointer;
  margin-right: 10px;
}
.staff-item.selected {
  background-color: #d1ecf1; /* A light background color */
  font-weight: bold;
  color: #0c5460; /* Darker text for contrast */
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  width: 25%; /* Set the popup width to 25% of the current size */
  max-width: 200px; /* Optional: Limit the max width if you want to prevent it from being too wide on large screens */
  background: white; /* Popup background color */
  padding: 20px; /* Add padding to the content */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

 