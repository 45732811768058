 /* Organisation.css */

.organisation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Stack buttons above the panels */
}

.form-buttons {
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 10px; /* Space between buttons */
    margin-bottom: 20px; /* Space below buttons */
}

button {
    padding: 8px 16px; /* Standard button size */
    font-size: 14px; /* Standard font size */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.2s; /* Smooth color transition */
}

button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

button:disabled {
    background-color: #ccc; /* Disabled color */
    cursor: not-allowed; /* Not allowed cursor */
}

.panels-container {
    display: flex; /* Use a new flex container for panels */
    gap: 20px; /* Space between panels */
}

.panel {
    flex: 1; /* Each panel will take equal space */
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.panel h3 {
    font-size: 1.2em;
    color: #444;
    margin-top: 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

label {
    font-weight: bold;
    color: #555;
}

label input,
label select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

input[type="number"],
input[type="text"],
input[type="email"],
select {
    box-sizing: border-box;
    font-family: inherit;
}

input[type="radio"] {
    margin-right: 5px;
}

.paycycle-options {
    display: flex;
    gap: 20px;
    margin-top: 5px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .organisation-container {
        padding: 15px;
    }

    button {
        width: 100%; /* Full width buttons on small screens */
        font-size: 14px; /* Standard font size */
    }

    .form-buttons {
        flex-direction: column; /* Stack buttons vertically on small screens */
        gap: 10px; /* Space between buttons */
    }

    .panels-container {
        flex-direction: column; /* Stack panels vertically on small screens */
    }
}
