  /* HolidayRota.css */
  .holiday-planner-container {
    padding: 20px;
  }
  
  /* Header section including title and role labels */
  .planner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Title of the planner */
  .planner-title {
    font-size: 24px;
    margin: 0;
  }
  
  /* Container for role labels */
  .roles-row {
    display: flex;
    flex-wrap: wrap; /* Wrap labels if they exceed container width */
    gap: 10px; /* Space between role labels */
    margin-bottom: 20px; /* Space below role labels */
  }
  
  /* Individual role label styling */
  .role-indicator {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9; /* Background color for visibility */
    color: black; /* Text color for visibility */
    text-align: center; /* Center-align text */
    font-weight: bold; /* Make text bold */
    font-size: 14px; /* Adjust font size as needed */
  }
  
  /* Container for year selector */
  .year-selector {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements in the selector */
    margin-bottom: 20px; /* Ensure space below the selector */
  }
  
  .year-selector select {
    margin-right: 10px;
    padding: 5px;
  }
  
  .year-selector button {
    margin-left: 5px;
    padding: 5px 10px;
  }
  
  .year-grid {
    display: flex;
    flex-direction: column;
  }
  
  .month-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .month-name {
    font-weight: bold;
    width: 50px;
    text-align: right;
    margin-right: 10px;
  }
  
  .calendar-day {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack day name above day number */
    justify-content: center;
    align-items: center;
    font-size: 10px;
    box-sizing: border-box;
    padding: 5px;
    min-width: 42px;
    margin-right: 1px;
  }
  
  .calendar-day.weekend {
    background-color: #d3d3d3; /* Grey background for weekends */
  }
  
  .calendar-day span {
    display: block;
  }
  
  /* Smaller text for the day name */
  .calendar-day .day-name {
    font-size: 10px;
    font-weight: bold;
  }
  
  .calendar-day .day-number {
    font-size: 12px;
  }
  
  .holiday-dots {
    display: flex;
    flex-direction: row;
    gap: 2px; /* Adjust gap as needed */
  }
  
  .holiday-dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 2px;
    background-color: #FF0000; /* Default color, will be overridden by inline styles */
  }
  .holiday-popup {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 300px;
    overflow: auto;
    font-size: 14px;
    z-index: 1000;
    display: block; /* Ensure popup is displayed only when content is present */
  }
  
  .holiday-popup ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .holiday-popup li {
    margin-bottom: 5px;
  }
  
  .popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    /* Ensure popup is wide enough */
    min-width: 300px; /* Adjust as needed */
    max-width: 500px; /* Adjust as needed */
  }
  
  .popup-entry {
    margin-bottom: 10px; /* Space between entries */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .popup-entry div {
    font-size: 10px; /* Change font size to 10px */
  }
  
  .weekend {
    border: 3px solid #000; /* Thicker border for weekends */
  }
  
  .weekday {
    border: 2px solid #ccc; /* Normal border for weekdays */
  }
  