.book-locum {
    display: flex;
    flex-direction: column;
  }
  
  .locum-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .locum-field label {
    margin-right: 5px; /* Adjust spacing as needed */
  }
  
  .locum-field input {
    margin-right: 10px; /* Adjust spacing as needed */
  }
  
  .locum-field span {
    margin: 0 5px; /* Space between date and time fields */
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .title {
    font-size: 18px; /* Smaller font size for the title */
  }
  
  .created-field {
    display: flex;
    align-items: center;
    font-size: 14px; /* Smaller font size for the 'Created' field */
  }
  
  .created-field label {
    margin-right: 10px;
  }
  
  .locum-field {
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"],
  select {
    font-size: 14px; /* Consistent font size for inputs and selects */
  }
  
  /* Container that holds the floating modal */
.book-locum {
  position: fixed; /* Ensures the dialog floats over the rest of the content */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Move the modal to the exact center */
  background-color: white; /* White background for the dialog */
  padding: 20px; /* Some padding for layout */
  z-index: 1000; /* Ensures it is on top of everything else */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
  border-radius: 8px; /* Rounded corners */
  width: 500px; /* Width of the modal */
  max-width: 100%; /* Make sure it’s responsive */
  box-sizing: border-box;
}

/* Fullscreen overlay when modal is open */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 999; /* Behind the modal but above the other content */
}

/* Additional styles for buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #ddd;
}
