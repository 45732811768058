.homepage {
  display: flex;
  flex-direction: column;
  width: 100vw; /* Ensure homepage takes up full viewport width */
}

.filter-container {
  margin-bottom: 1rem;
}

.main-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 1rem;
  width: 100%; /* Ensure the grid takes up the full width of the screen */
  box-sizing: border-box; /* Include padding and borders in width calculation */
}

.column {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  min-height: 200px; /* Optional: ensures the columns have a minimum height */
  box-sizing: border-box; /* Ensures border and padding are within width */
}

.performance-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column size */
  grid-gap: 20px; /* Spacing between grid items */
  padding: 10px; /* Add some padding around the grid */
  border: 2px solid #ccc; /* Optional: Add a border around the container */
  border-radius: 10px; /* Optional: Rounds the corners of the grid container */
}


.grid-item {
  display: flex;
  justify-content: space-between;
   padding: 0.25rem; /* Narrower padding */
  font-size: 0.875rem; /* Smaller font size */

  border-bottom: 1px solid #eee;
}

.grid-item.vertical-item {
  flex-direction: column; /* Display vertically */
}

.bordered {
  border: 1px solid #ccc;
}

  
.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.staff-sickness-records {
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid #ccc; /* Add border */
  border-radius: 8px; /* Optional: Add rounded corners */
  background-color: #f9f9f9; /* Optional: Add background color for contrast */
}

.sickness-list,
.log-events-list {
  margin: 0;
  padding: 0;
  list-style: none;
}


.holiday-list,
.log-events-list {
  margin: 0;
  padding: 0;
  list-style: none;
}


.sickness-list li,
.log-events-list li {
  margin-bottom: 10px;
}


.holiday-list li,
.log-events-list li {
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 0.5rem; /* Space between label and input */
}

 
.performance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 /* width: 5cm; /* Fix the width of the container at 5cm */
}


.performance-grid-container {
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 7cm; /* Fix the width of the container at 5cm */
}

.performance-container h3 {
  margin: 0; /* Ensure there is no extra margin */
}

.performance-container button {
  margin-left: auto; /* Ensure the button is aligned to the right */
}


.popup {
  position: fixed; /* This positions the popup relative to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensures the popup is centered */
  z-index: 1000; /* Ensure it is above other elements */
  background-color: white; /* Background color for contrast */
  padding: 20px; /* Add padding for spacing inside */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a shadow for visibility */
}

.popup-overlay {
  position: fixed; /* Fullscreen overlay */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Below the popup but above the rest of the content */
}

/* Optional: Ensure modal is displayed correctly */
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}


.log-events-grid {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px; /* Set a max height to limit the grid's height */
  overflow-y: auto;  /* Add vertical scrollbar when content overflows */
}

.log-event-item {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.log-event-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}


/* Add this style to move the graph down */
.graph-container {
  margin-top: 20px; /* Adjust as needed to create space */
}


/* Ensure the html and body take up the full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto; /* Allow scrolling */
}

/* Ensure the homepage takes up at least 100% of the viewport height */
.homepage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh; /* Ensure the homepage takes the full viewport height */
  box-sizing: border-box; /* Ensure padding and borders are included in height/width */
  overflow-y: auto; /* Enable scrolling when content overflows */
}

