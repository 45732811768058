 /* Modal overlay */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content container */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
}

/* Close button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above modal content */
}

/* Modal body */
.modal-body {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 15px; /* Add space between form elements */
}

/* Form elements */
.modal-body label {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
}

.modal-body input,
.modal-body textarea,
.modal-body select {
  width: 100%;
  padding: 8px;
  margin-top: 5px; /* Space between label and input */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-body button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.modal-body button:hover {
  background-color: #0056b3;
}
