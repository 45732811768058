/* App.css */

/* Container for the whole app */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header styles */
.app-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.logo {
  height: 50px; /* Adjust height as needed */
  margin-right: 20px; /* Space between logo and header text */
}

.header-buttons {
  margin-left: auto; /* Push buttons to the right */
}

.header-button {
  margin-left: 10px;
  text-decoration: none;
  color: #007bff; /* Text color */
  padding: 12px 15px; /* Increased padding for larger buttons */
  background-color: transparent; /* Ensure no background color */
  border: none; /* Remove any borders, if present */
  border-radius: 4px; /* Optional: Add slight rounding to corners */
}

.header-button:hover {
  text-decoration: underline;
  background-color: transparent; /* Ensure hover doesn't change background */
}

/* Main content styles */
.app-main {
  display: flex;
  flex: 1;
  padding: 10px;
}

/* Home page layout */
.home-content {
  display: flex;
  width: 100%;
}

/* Panel styles */
.left-panel,
.right-panel {
  flex: 1; /* Take equal width */
  padding: 10px;
}

/* Optional: Add borders to distinguish panels */
.left-panel {
  border-right: 1px solid #ccc;
}

.right-panel {
  border-left: 1px solid #ccc;
}

/* Styles for the holiday dialog */
.holiday-dialog {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background: white;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dialog-buttons {
  margin-top: 10px;
}
