.holiday-dialog {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.entry-grid {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to new rows */
  overflow-x: auto; /* Enable horizontal scrolling for the entries */
  padding: 10px 0;
}

.entry-item {
  flex: 0 0 auto; /* Prevent items from shrinking */
  border: 1px solid #ddd;
  padding: 15px;
  margin-right: 15px; /* Space between entries */
  border-radius: 5px;
  background-color: #f9f9f9;
  min-width: 300px; /* Set a minimum width for each entry */
  box-sizing: border-box;
  max-height: 500px; /* Limit the height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.entry-item p {
  margin: 5px 0;
}

.field-container {
  margin-bottom: 15px;
}

.field-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.field-container input,
.field-container select,
.field-container textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.field-container textarea {
  height: 80px;
}

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

/* Corrected button styles with padding */
.edit-button,
.delete-button,
.save-button,
.cancel-button {
  padding: 8px 12px;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.edit-button {
  background-color: #4CAF50;
}

.delete-button {
  background-color: #f44336;
}

.save-button {
  background-color: #2196F3;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

/* New CSS for Close Button Positioning */
.holiday-dialog .dialog-controls {
  display: flex;
  justify-content: flex-end; /* Align the close button to the right */
  margin-top: 20px;
}

.holiday-dialog button.close-button {
  padding: 8px 15px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* Field container adjustments */
.comment-textarea, 
.manager-comment-textarea {
  height: 50px; /* Adjust the height as needed */
}

.field-container-inline {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.field-container-inline label {
  margin-right: 10px;
  flex: 1;
}

.field-container-inline input {
  flex: 2;
}

/* Style for the 'Add New' button */
.add-button {
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  padding: 5px 10px; /* Adjust padding */
  font-size: 18px; /* Adjust font size */
  cursor: pointer; /* Pointer cursor on hover */
  margin-right: 10px; /* Space between buttons */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center icon and text vertically */
}

.add-button::before {
  content: '+';
  font-size: 24px; /* Icon size */
  margin-right: 5px; /* Space between icon and text */
}

.add-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Style for the 'Close' button */
.close-button {
  background-color: #6c757d; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  padding: 5px 10px; /* Adjust padding */
  font-size: 14px; /* Smaller font size */
  cursor: pointer; /* Pointer cursor on hover */
  position: absolute; /* Position button to the left side */
  top: 10px; /* Distance from the top */
  left: 10px; /* Distance from the left */
}

.close-button:hover {
  background-color: #5a6268; /* Darker shade on hover */
}


.field-container-unpaidallowance {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust spacing between fields */
}

.field-container-unpaidallowance label{
  margin-right: 10px; /* Adds space between the label and input */
}

.unpaidallowancecheckbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Optional: Adds space between lines */
}

.unpaidallowancecheckbox-container label {
  margin-right: 10px; /* Adjust spacing between the label and the checkbox */
}
