.locum-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.locum-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 90vh; /* 90% of the viewport height to allow scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.locum-grid-box {
    margin-bottom: 20px;
    max-height: 300px; /* Set a fixed height for the grid box */
    overflow-y: auto;  /* Enable vertical scrolling inside the grid box */
}

.locum-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* Seven columns for each field */
    gap: 10px;
}

.locum-grid-header {
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
}

.locum-grid-item {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

button {
    padding: 5px 10px; /* Decrease padding for a smaller button */
    font-size: 12px;   /* Adjust the font size to make the text smaller */
    background-color: #007bff; /* Keep your desired background color */
    color: white; /* Keep the text color */
    border: none; /* Keep border settings */
    border-radius: 3px; /* Optional: reduce border radius for a sharper look */
    cursor: pointer; /* Remains unchanged */
}

button:hover {
    background-color: #0056b3; /* Keep hover color */
}