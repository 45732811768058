 /* General Layout for Panels */
.sop-container {
  display: flex;
  gap: 20px; /* Space between the panels */
  position: relative; /* Keep panels in place */
  z-index: 1; /* Ensure the panels are behind the popup */
}

.panel {
  flex: 1; /* Distribute space evenly across panels */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  max-height: 600px; /* Optional: Adjust based on the height you want */
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  gap: 10px; /* Space between rows */
  position: relative; /* Ensure the button is positioned relative to this panel */
}

/* Role and checkbox alignment */
.role-item {
  display: flex;
  align-items: center; /* Align role and checkbox vertically */
  gap: 8px; /* Space between role text and checkbox */
}

/* Button styles */
button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

/* Versions Button - Custom Changes */
.versions-button {
  padding: 4px; /* Smaller padding */
  font-size: 10px; /* Reduce font size by 40% from original */
  width: auto; /* Make the width just fit the content (V) */
  height: auto; /* Adjust height to match the width */
  text-align: center; /* Center the text */
  border-radius: 50%; /* Make it circular */
  font-weight: bold; /* Make the letter 'V' stand out */
}

.versions-button span {
  font-size: 10px; /* Reduce font size of 'V' */
}

/* Highlight the selected item */
.panel-item.selected {
  background-color: #007bff; /* Blue background for selected item */
  color: white; /* White text */
  border: 2px solid #0056b3; /* Darker border for selected item */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.6); /* Optional shadow effect */
}

.panel-item.selected button {
  background-color: #0056b3; /* Change the button color to match */
  color: white; /* Ensure button text is visible */
}

/* Position the button at the end of each item row */
.sop-item {
  display: flex;
  justify-content: space-between; /* Position the button at the end of the row */
  align-items: center; /* Align the items vertically */
  border: 1px solid #ccc; /* Border around each item */
  padding: 10px; /* Padding inside each item */
  border-radius: 4px; /* Rounded corners for each item */
  margin-bottom: 10px; /* Space between items */
  position: relative; /* Ensure button positioning works inside the item */
}

/* Position the button on each line of the leftmost panel */
.panel-item button.versions-button {
  width: 24px;  /* Just enough for the letter 'V' */
  height: 24px; /* Ensure button is square */
  padding: 0; /* Remove any extra padding */
  margin-left: 10px; /* Space between button and the item */
  text-align: center; /* Center the 'V' inside the button */
  line-height: 24px; /* Vertically center the 'V' */
  border-radius: 50%; /* Round the button */
  font-size: 10px; /* Decrease font size of 'V' */
  display: inline-block; /* Make it inline with the content */
  position: absolute; /* Position button absolutely within the panel */
  right: 10px; /* Align the button to the right edge of the panel */
  top: 50%; /* Vertically center the button within the row */
  transform: translateY(-50%); /* Adjust for exact vertical centering */
  padding-left: 30px; /* Add padding to the left of the button */
}

/* Popup Dialog Styles */
.sop-dialog {
  position: fixed; /* Fixed position relative to the screen */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure the popup is exactly centered */
  background-color: white; /* Set a white background for the popup */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for the popup */
  z-index: 1000; /* Ensure the popup is on top of other content */
  width: 300px; /* Adjust the width as needed */
}

/* Overlay behind the popup to darken background */
.sop-dialog-overlay {
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.7); /* Grey background with transparency */
  z-index: 999; /* Ensure the overlay is below the popup */
  pointer-events: all; /* Block interactions with the background */
}

/* Content inside the popup */
.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* To ensure the panels do not shift or get blocked when the popup is open */
.sop-container {
  position: relative; /* Ensure it remains behind the popup */
  z-index: 1;
}

/* Additional panel styling */
.panel {
  overflow-y: auto; /* Ensure content is scrollable if needed */
  max-height: 600px; /* Adjust depending on your layout */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
