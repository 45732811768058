/* Ensure the popup overlay covers the entire screen */
/* Ensure the popup overlay covers the entire screen */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure popup is above all other content */
}

 
/* Buttons at the bottom of the popup */
.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.popup-buttons button {
  padding: 8px 16px;
  font-size: 14pt; /* Increased from 12pt to 14pt */
  cursor: pointer;
}

.popup-buttons .save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
}

.popup-buttons .cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 3px;
}

/* Adjust font sizes for popup content */
.popup-content p {
  font-size: 12pt; /* Increased from 10pt to 12pt */
}

/* Global reset for html and body to avoid unwanted overflow or sizing issues */
html, body {
  overflow: hidden; /* Prevent scrolling behind the popup */
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.highlight {
  background-color: blue;
  color: white; /* Optional: changes text color for better contrast */
  padding: 2px; /* Optional: adds padding for better appearance */
  border-radius: 3px; /* Optional: rounds corners for aesthetics */
}

.radio-group {
  padding-top: 0;  /* Remove top padding */
  padding: 0;      /* Remove padding inside the border */
  display: flex;
  flex-direction: column;  /* Make the radio buttons display vertically */
  border: 1px solid #ccc;  /* Add a border around the radio group */
  border-radius: 5px;      /* Optional: Round the corners of the border */
  max-width: 300px;        /* Optional: Set a max width for the radio group */
  font-size: 12pt; /* Increase font size for radio group labels */
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 0; /* Remove spacing between children */
}

/* Ensure there's no margin on elements inside the panel */
.panel p,
.panel input,
.panel textarea,
.panel .radio-group {
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  font-size: 12pt; /* Increase the font size of the text elements in panel */
}

/* Optional: If you want to maintain some spacing between labels and inputs but not create vertical gaps */
input, textarea {
  margin-top: 2px; /* Adjust to your preference */
  font-size: 12pt; /* Increase input/textarea font size */
}

.radio-group label {
  margin-right: 10px;
  font-size: 12pt; /* Increase label font size */
}


/* Ensure the popup is 75% of the viewport width */
.popup {
  width: 85vw !important; /* Force 75% of the viewport width */
  min-width: 85vw !important; /* Enforce minimum width */
  height: auto; /* Adjust height based on content */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensure the popup stays above most elements */
}

/* Buttons at the bottom of the popup */
.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.popup-buttons button {
  padding: 8px 16px;
  font-size: 14pt; /* Increased from 12pt to 14pt */
  cursor: pointer;
}

.popup-buttons .save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
}

.popup-buttons .cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 3px;
}

/* Adjust font sizes for popup content */
.popup-content p {
  font-size: 12pt; /* Increased from 10pt to 12pt */
}

/* Global reset for html and body to avoid unwanted overflow or sizing issues */
html, body {
  overflow: hidden; /* Prevent scrolling behind the popup */
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.highlight {
  background-color: blue;
  color: white; /* Optional: changes text color for better contrast */
  padding: 2px; /* Optional: adds padding for better appearance */
  border-radius: 3px; /* Optional: rounds corners for aesthetics */
}

.radio-group {
  padding-top: 0;  /* Remove top padding */
  padding: 0;      /* Remove padding inside the border */
  display: flex;
  flex-direction: column;  /* Make the radio buttons display vertically */
  border: 1px solid #ccc;  /* Add a border around the radio group */
  border-radius: 5px;      /* Optional: Round the corners of the border */
  max-width: 300px;        /* Optional: Set a max width for the radio group */
  font-size: 12pt; /* Increase font size for radio group labels */
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 0; /* Remove spacing between children */
}

/* Ensure there's no margin on elements inside the panel */
.panel p,
.panel input,
.panel textarea,
.panel .radio-group {
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  font-size: 12pt; /* Increase the font size of the text elements in panel */
}

/* Optional: If you want to maintain some spacing between labels and inputs but not create vertical gaps */
input, textarea {
  margin-top: 2px; /* Adjust to your preference */
  font-size: 12pt; /* Increase input/textarea font size */
}

.radio-group label {
  margin-right: 10px;
  font-size: 12pt; /* Increase label font size */
}

.details-panel {
  width: 300px;
  padding: 10px;
  border: 1px solid black;
  max-height: 600px; /* Limit the height */
  overflow-y: auto;  /* Add vertical scrollbar if content overflows */
}

.hour-pattern-content .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* Add space between rows */
}

.hour-pattern-content h3 {
  margin: 0;
  width: 150px; /* Set a fixed width for the label */
  font-weight: normal; /* Optional: Adjust label styling */
}

.hour-pattern-content p {
  margin: 0;
  flex-grow: 1; /* Allow the value to take remaining space */
}
