/* Calendar.css */
.calendar-page {
  font-family: Arial, sans-serif;
  padding: 10px; /* Padding adjusted for smaller layout */
}

.calendar-title {
  text-align: center;
  font-size: 16px; /* Increased title font size by 2 points */
  margin-bottom: 15px; /* Adjusted margin */
}

.calendar-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adds space between controls */
  margin-bottom: 20px; /* Add space below the date picker */
}

.calendar-button {
  padding: 8px 12px; /* Adjust padding as needed */
}

.month-display {
  margin: 0 20px; /* Adds spacing around the month/date display */
}

.location-dropdown {
  margin-left: 10px; /* Adds spacing to the left of the dropdown */
  padding: 5px;
  font-size: 12px;
}


.role-dropdown {
  margin-left: 10px; /* Adds spacing to the left of the dropdown */
  padding: 5px;
  font-size: 12px;
}
.calendar-grid {
  display: grid;
  grid-template-columns: 120px repeat(31, 35px) 58px 58px 58px 58px; /* Adjusted column width for the last four columns */
  gap: 1px; /* Small gap between cells */
  overflow-x: auto; /* Horizontal scrolling if needed */
}

.calendar-cell {
  border: 1px solid #ddd;
  padding: 4px; /* Increased padding for better spacing */
  text-align: center;
  font-size: 10px; /* Increased font size by 2 points from the previous adjustment */
}

.label-cell {
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 12px; /* Increased font size by 2 points from the previous adjustment */
}

.name-cell {
  white-space: nowrap; /* Prevent text wrapping */
  background-color: #f4f4f4; /* Background color for the name column */
  font-weight: bold;
}

.calendar-grid > .calendar-cell:nth-child(-n+7) {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Styles for the bottom row */
.bottom-row {
  font-weight: bold; /* Make font bold */
  border-top: 2px solid #000; /* Solid line above the row */
  border-bottom: 2px solid #000; /* Solid line below the row */
}

@media (max-width: 600px) {
  .calendar-grid {
    grid-template-columns: 80px repeat(31, 25px) 42px 42px 42px 42px; /* Adjusted column width for mobile */
  }

  .calendar-cell {
    font-size: 8px; /* Increased font size by 2 points from the previous adjustment for mobile view */
    padding: 2px; /* Adjusted padding for mobile view */
  }

  .calendar-title {
    font-size: 14px; /* Increased title font size by 2 points from the previous adjustment for mobile view */
  }
}
