.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  width: 200vw; /* Set to 80% of viewport width */
  height: 80vh; /* Set to 80% of viewport height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup-content {
  display: flex;
  flex-direction: row; /* Align sections horizontally */
  height: 100%;
  width: 100%;
}

.popup-body {
  display: flex;
  height: 100%;
  width: 100%;
}

.popup-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
}

.popup-section.left {
  border-right: 2px solid blue;
}

.popup-section.middle {
  border-right: 2px solid blue;
}

.popup-panel {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
}

.upper {
  border-bottom: 2px solid blue;
}

.lower {
  margin-top: 10px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-close:hover {
  background-color: #0056b3;
}

.popup-separator {
  background-color: blue;
}

.vertical-separator {
  width: 2px;
  height: 100%;
}
