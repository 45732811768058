.floating-popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Hide scroll bars */
    width: 500px; /* Fixed width */
    height: 180px; /* Fixed height */
    font-family: monospace; /* Use monospace font for text to appear like typed */
  }
  
  .popup-content {
    white-space: pre-wrap; /* Preserve whitespace and newlines */
    margin: 0;
    padding: 10px 10px 10px 10px; /* Add padding */
  }
  
  /* Remove the .close-button styles */
  